import { CButton } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilVerticalAlignTop } from '@coreui/icons'
import React from 'react'
import { executeMutation } from '../../../common/utils/apiUtils'
import { useExportProductsMutation } from '../../store/productApi'
import { useDispatch } from 'react-redux'

export const ExportProductsToWMSButton = () => {
    const [exportProducts, { isLoading: isExporting }] = useExportProductsMutation()
    const dispatch = useDispatch()

    const handleExportProducts = () => {
        executeMutation(
            exportProducts(),
            dispatch,
            'Startet eksport til WMS',
            'Kunne ikke eksportere til WMS',
            () => {}
        )
    }

    return (
        <CButton
            className={'mb-3 mr-1'}
            color={'primary'}
            onClick={() => {
                handleExportProducts()
            }}
            disabled={isExporting}
        >
            <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
            Eksporter til WMS
        </CButton>
    )
}
