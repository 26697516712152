export interface Order {
    id: number
    orderGroupId: number
    bookingTime: string
    email: string
    customer: OrderCustomer
    sessionId: string
    orderLines: OrderLine[]
    externalId: number
    status: OrderStatus
    address: Address
    deliveryDate: string
    region: Region
    savedAt: string
    active: boolean
    locked: boolean
    totalDiscounts: number
    freightCost: number
    calculatedVat: number
    regionId: number
    calculatedPrice: number
    cancelledOrFailed: boolean
    subscriptionOrder: boolean
    priceForFreightCalculation: number
    gift: boolean
    customerInformation: { [key: string]: string }
}

export enum OrderStatus {
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
    ACTIVE = 'ACTIVE',
    CREDITED = 'CREDITED',
}

export interface OrderLine {
    product: OrderProduct | undefined
    quantity: number
    isSubscription: boolean
    discountPerItem: OrderLineDiscount
    pricePerItem: Price
    productItemId: number
    externalProductId: number
    title: string
    grossPriceWithQuantity: OrderLineGrossPriceAndQuantity
    bundle: boolean
    name: string
    totalPrice: Price
    pricePerItemAfterDiscount: Price
    vatRate: number
    bundleLines: OrderLine[] | undefined
}

export interface Address {
    addressId: number
    addressCoreId: number
    customerId: number
    type: string
    firstName: string
    lastName: string
    mobile: string
    deliverySms: boolean
    regionId: number
    deliveryPointId: number
    street: string
    streetNumber: number
    zipCode: string
    zipName: string
    countryCode: string
    active: boolean
}

export interface Region {
    regionId: number
    transportProduct: string
    externalRegion: string
    dayMatrixId: number
    enabled: boolean
    name: string
}

export interface OrderLineGrossPriceAndQuantity {
    price: number
    vatRate: number
    quantity: number
}

export interface Price {
    gross: number
    net: number
    vatRate: number
    vatAmount: number
}

export interface OrderLineDiscount {
    externalId: number
    discount: number
}

export interface OrderProduct {
    id: number
    externalId: number
    subscriptionProduct: boolean
    b2bProduct: boolean
    regionId: number
    price: number
    vatRate: number
    currency: string
    weight: number
    title: string
    vendor: string
    imageUrl: string
    subscriptionPrice: number
    bundle: boolean
    productType: string
    supplierId: number
    freightProduct: boolean
}

export interface OrderCustomer {
    customerId: number
    email: string
    deliverySms: boolean
    name: string
    authenticated: boolean
}

export enum PaymentStatus {
    NEW = 'NEW',
    INITIALIZED = 'INITIALIZED',
    AUTHORIZED = 'AUTHORIZED',
    PENDING = 'PENDING',
    PENDING_AUTHORIZE = 'PENDING_AUTHORIZE',
    PENDING_CREDIT = 'PENDING_CREDIT',
    PENDING_COMPLETE = 'PENDING_COMPLETE',
    PENDING_CAPTURE = 'PENDING_CAPTURE',
    PENDING_CANCEL = 'PENDING_CANCEL',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
    CREDITED = 'CREDITED',
    CANCELLED = 'CANCELLED',
    CHALLENGE_REQUIRED = 'CHALLENGE_REQUIRED',
    UNKNOWN = 'UNKNOWN',
    VERIFIED = 'VERIFIED',
    ERROR = 'ERROR',
    ABANDONED = 'ABANDONED',
}

export interface PaymentEvent {
    paymentStatus: PaymentStatus
    created: string
}

export interface PaymentOrderLine {
    discountAmount: number
    pricePerItemIncVatAndDiscount: number
    grossTotal: number
    itemReference: string
    orderId: number
    paymentDetailId: number
    paymentGatewayOrderItemId: number
    paymentId: number
    price: number
    productItemId: number
    quantity: number
    vatrate: number
    refundedAmount: number
    refunds: PaymentOrderLineRefund[]
}

export interface PaymentOrderLineRefund {
    creditedAmount: number
    message: string
    creditedVatAmount: number
    isPending: boolean
    refundId: number
    refundItemId: number
}

export interface PaymentWithHistory {
    paymentId: string
    created: string
    invoiceNumber: string
    psp: string
    status: PaymentStatus
    netAmount: number
    vatAmount: number
    failureCode: string
    paymentGatewayOrderId: string
    paymentGatewayMethodId: string
    events: PaymentEvent[]
    lines: PaymentOrderLine[]
}

export interface Payment {
    paymentId: string
    created: string
    invoiceNumber: string
    psp: string
    status: PaymentStatus
    netAmount: number
    vatAmount: number
    failureCode: string
    paymentGatewayOrderId: string
    paymentGatewayMethodId: string
}

export interface IndexedOrder {
    id: string
    externalId: string
    customer: IndexedCustomer
    deliveryDate: string
    address: IndexedAddress
    couponCode: string
    totalPrice: number
    region: string
    status: OrderStatus
}

export interface IndexedAddress {
    street: string
    zip: string
    city: string
}

export interface IndexedCustomer {
    customerId: string
    email: string
    name: string
}

export interface OrderSearchResult {
    hits: number
    orders: IndexedOrder[]
}

export interface WmsOrder {
    orderId: string
    shipmentId: string | null
    status: SalesOrderStatus | null
    deliveryDate: string | null
}

export interface UpdateWmsOrderRequest {
    status: SalesOrderStatus
}

export enum SalesOrderStatus {
    READY_FOR_PICKING = 'READY_FOR_PICKING',
    AWAIT = 'AWAIT',
    PICKED = 'PICKED',
    CANCELLED = 'CANCELLED',
}
