import { CAlert, CButton, CCardBody, CCardFooter, CCardText, CCol, CRow, CSpinner } from '@coreui/react-pro'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import {
    useCapturePaymentMutation,
    useGetPaymentByOrderIdQuery,
    useSendReceiptMutation,
} from '../store/paymentAdminApi'
import PaymentHistoryTable from './PaymentHistoryTable'
import PaymentInfoTable from './PaymentInfoTable'
import PaymentStatusBadge from './PaymentStatusBadge'
import Toolbar from '../../common/components/Toolbar'
import React, { useState } from 'react'
import CreateRefundModal from './CreateRefundModal'
import Card from '../../common/components/Card'
import CardHeader from '../../common/components/CardHeader'

export interface PaymentCardProps {
    orderId: string
}

function PaymentDetailsCard({ orderId }: PaymentCardProps) {
    const userCanEdit = userHasSomePermissions(useAuthenticatedUser(), 'orders.write')
    const { data: payment, isLoading, isError, error, isSuccess } = useGetPaymentByOrderIdQuery(orderId)
    const [sendReceipt, { isLoading: receiptLoading }] = useSendReceiptMutation()
    const [capture, { isLoading: captureLoading }] = useCapturePaymentMutation()
    const [showRefundModal, toggleRefundModal] = useState(false)

    return (
        <>
            <Card>
                <CardHeader>
                    <CRow className={'mb-3'}>
                        <CCol>Betaling {payment && <>#{payment.paymentId}</>}</CCol>
                        <CCol sm={5}>{payment && <PaymentStatusBadge paymentStatus={payment.status} />}</CCol>
                    </CRow>
                </CardHeader>
                <CCardBody>
                    <CCardText>
                        {isLoading && <CSpinner />}
                        {isError && (
                            <CAlert color={'danger'}>
                                Det har skjedd en feil <br /> {JSON.stringify(error)}
                            </CAlert>
                        )}
                        {isSuccess && payment && (
                            <>
                                <PaymentInfoTable payment={payment} />
                                <PaymentHistoryTable events={payment.events} />
                            </>
                        )}
                    </CCardText>
                </CCardBody>
                <CCardFooter>
                    {payment && (
                        <Toolbar>
                            <CButton
                                className={'mr-1'}
                                onClick={() => sendReceipt(payment.paymentId)}
                                disabled={!userCanEdit || receiptLoading}
                            >
                                Send kvittering
                            </CButton>
                            <CButton
                                className={'mr-1'}
                                color={'warning'}
                                disabled={!userCanEdit || captureLoading || payment.status != 'AUTHORIZED'}
                                onClick={() => capture(payment.paymentId)}
                            >
                                Trekk penger
                            </CButton>
                            <CButton color={'danger'} variant={'outline'} onClick={() => toggleRefundModal(true)}>
                                Opprett refusjon
                            </CButton>
                        </Toolbar>
                    )}
                </CCardFooter>
            </Card>
            <CreateRefundModal visible={showRefundModal} onClose={() => toggleRefundModal(false)} orderId={orderId} />
        </>
    )
}

export default PaymentDetailsCard
