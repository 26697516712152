import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import { useExportShippedOrdersToWmsMutation, useExportUnshippedOrdersToWmsMutation } from '../store/orderAdminApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

export const ExportOrdersToWMSButton = ({ ...rest }) => {
    const [exportOrders, { isLoading: isExporting }] = useExportUnshippedOrdersToWmsMutation()
    const [exportShippedOrders, { isLoading: isExportingShipped }] = useExportShippedOrdersToWmsMutation()
    const dispatch = useDispatch()
    const exportOrdersToWMS = () => {
        if (
            !confirm(
                'Er du sikker på at du vil eksportere alle ordre som ikke er booket til WMS?\n\n' +
                    'Dette gjelder kun ordre i regioner der WMS er aktivert.'
            )
        ) {
            return
        }

        executeMutation(exportOrders(), dispatch, 'Orders exported to WMS', 'Could not export orders to WMS', () => {})
    }
    const exportShippedOrdersToWMS = () => {
        if (
            !confirm(
                'Er du sikker på at du vil eksportere alle ordre som ikke er booket til WMS?\n\n' +
                    'Dette gjelder kun ordre i regioner der WMS er aktivert.'
            )
        ) {
            return
        }

        executeMutation(
            exportShippedOrders(),
            dispatch,
            'Orders exported to WMS',
            'Could not export orders to WMS',
            () => {}
        )
    }

    return (
        <CDropdown>
            <CDropdownToggle disabled={isExporting || isExportingShipped} {...rest}>
                Eksporter ordre til WMS
            </CDropdownToggle>
            <CDropdownMenu>
                <CDropdownItem onClick={exportShippedOrdersToWMS}>Shipped</CDropdownItem>
                <CDropdownItem onClick={exportOrdersToWMS}>Unshipped</CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    )
}
