import { CButton } from '@coreui/react-pro'
import { craftUrl } from '../../../config'
import CIcon from '@coreui/icons-react'
import { cilExternalLink } from '@coreui/icons'
import React from 'react'

export const CreateNewProductButton = () => {
    return (
        <CButton
            as={'a'}
            href={`${craftUrl}/admin/entries/groceries/new`}
            target={'_blank'}
            type="button"
            variant="outline"
            className={'mb-3 mr-1'}
        >
            <CIcon className={'mr-1'} icon={cilExternalLink} size="sm" />
            Opprett nytt produkt
        </CButton>
    )
}
