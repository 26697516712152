import { FC } from 'react'
import { useGetWmsOrderQuery, useUpdateWmsOrderMutation } from '../store/orderAdminApi'
import { ErrorResponse } from '../../common/types'
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CInputGroup,
    CInputGroupText,
    CSpinner,
} from '@coreui/react-pro'
import { SalesOrderStatus } from '../types'
import { getColorForWmsSalesOrderStatus, getLabelForWmsSalesOrderStatus } from '../orderstatus'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

interface WmsOrderStatusDropdownProps {
    orderId: string
}

const isErrorResponse = (error: unknown): error is ErrorResponse => {
    return (error as ErrorResponse)?.status !== undefined
}

export const WmsOrderStatusDropdown: FC<WmsOrderStatusDropdownProps> = ({ orderId }) => {
    const { data: wmsOrder, isLoading, error } = useGetWmsOrderQuery(orderId)
    const [updateWmsOrder] = useUpdateWmsOrderMutation()
    const dispatch = useDispatch()
    const handleSetStatus = (status: SalesOrderStatus) => {
        executeMutation(
            updateWmsOrder({ orderId, body: { status } }),
            dispatch,
            'WMS Status updated',
            'Could not update WMS Status'
        )
    }

    if (isLoading) {
        return (
            <CInputGroup>
                <CInputGroupText>
                    <CSpinner />
                </CInputGroupText>
            </CInputGroup>
        )
    }

    if (isErrorResponse(error) && error.status === 404) {
        return (
            <CInputGroup>
                <CInputGroupText>{error.data.message || 'Not found'}</CInputGroupText>
            </CInputGroup>
        )
    }

    if (error || !wmsOrder) {
        return (
            <CInputGroup>
                <CInputGroupText>Unknown Error</CInputGroupText>
            </CInputGroup>
        )
    }

    return (
        <CInputGroup>
            <CInputGroupText>WMS Status:</CInputGroupText>
            <CDropdown>
                <CDropdownToggle
                    color={getColorForWmsSalesOrderStatus(wmsOrder.status)}
                    disabled={wmsOrder.status === null || wmsOrder.status === SalesOrderStatus.PICKED}
                >
                    {getLabelForWmsSalesOrderStatus(wmsOrder.status)}
                </CDropdownToggle>
                <CDropdownMenu>
                    {Object.values(SalesOrderStatus).map((status) => (
                        <CDropdownItem
                            color={getColorForWmsSalesOrderStatus(status)}
                            role={'button'}
                            key={status}
                            onClick={() => handleSetStatus(status)}
                        >
                            {getLabelForWmsSalesOrderStatus(status)}
                        </CDropdownItem>
                    ))}
                </CDropdownMenu>
            </CDropdown>
        </CInputGroup>
    )
}
