import React, { useState } from 'react'
import {
    CFormInput,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CModal,
    CModalContent,
    CFormSelect,
} from '@coreui/react-pro'
import { sortingApi, sortingGroupOptions } from '../store/sortingApi'
import { Page } from '../../common'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

export default function SortingPage() {
    const { data: sortingAliases } = sortingApi.useListQuery()
    const [alias, setAlias] = useState('')
    const [aliasFor, setAliasFor] = useState('')
    const [aliasGroup, setAliasGroup] = useState('')
    const [editingId, setEditingId] = useState<number | null>(null)
    const [createAlias] = sortingApi.useCreateSortingAliasMutation()
    const [deleteAlias] = sortingApi.useDeleteSortingAliasMutation()
    const [updateAlias] = sortingApi.useUpdateSortingAliasMutation()
    const dispatch = useDispatch()
    const handleCreateAlias = () => {
        executeMutation(
            createAlias({ alias, aliasFor, aliasGroup }),
            dispatch,
            'Alias opprettet',
            'Kunne ikke opprette alias'
        )
    }

    const handleChangeAlias = (id: number) => {
        if (!sortingAliases) {
            return
        }

        const existingAlias = sortingAliases.find((sortingAlias) => sortingAlias.id === id)
        if (!existingAlias) {
            return
        }

        setAlias(existingAlias.alias)
        setAliasFor(existingAlias.aliasFor)
        setAliasGroup(existingAlias.aliasGroup)
        setEditingId(id)
    }

    const handleUpdate = () => {
        const existingAlias = sortingAliases?.find((sortingAlias) => sortingAlias.id === editingId)

        if (editingId == null) {
            return
        }

        if (!existingAlias) {
            return
        }

        executeMutation(
            updateAlias({
                id: existingAlias.id,
                alias,
                aliasFor,
                aliasType: existingAlias.aliasType,
                aliasGroup,
            }),
            dispatch,
            'Alias endret',
            'Kunne ikke endre alias'
        )
        setEditingId(null)
    }

    return (
        <Page
            title={'Utsortering'}
            preamble={
                'Her kan du koble bilnummer til alias. Aliaset vil dukke opp på etiketten slik at det blir enklere å\n' +
                '                sortere ut'
            }
        >
            <CModal visible={editingId != null}>
                <CModalContent className={'p-5'}>
                    <CTableRow>
                        <CTableDataCell>
                            <CFormInput
                                placeholder={'Alias'}
                                value={alias}
                                onChange={(e) => setAlias(e.target.value)}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormInput
                                placeholder={'Bilnummer'}
                                value={aliasFor}
                                onChange={(e) => setAliasFor(e.target.value)}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormSelect
                                placeholder={'Gruppe'}
                                options={sortingGroupOptions}
                                value={aliasGroup}
                                onChange={(e) => setAliasGroup(e.currentTarget.value)}
                            />
                            <p>{aliasGroup}</p>
                        </CTableDataCell>
                        <CTableDataCell>
                            <CButton color={'success'} onClick={handleUpdate}>
                                Endre alias
                            </CButton>
                        </CTableDataCell>
                    </CTableRow>
                </CModalContent>
            </CModal>
            <CTable>
                <CTableHead>
                    <CTableRow>
                        <CTableHeaderCell>Alias</CTableHeaderCell>
                        <CTableHeaderCell>Bilnummer</CTableHeaderCell>
                        <CTableHeaderCell>Gruppe</CTableHeaderCell>
                        <CTableHeaderCell>Endre</CTableHeaderCell>
                        <CTableHeaderCell>Slett</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody>
                    {[...(sortingAliases || [])]
                        ?.sort((alias1, alias2) => alias1?.alias?.localeCompare(alias2?.alias))
                        ?.map((sortingAlias) => {
                            return (
                                <CTableRow key={sortingAlias.id}>
                                    <CTableDataCell>{sortingAlias.alias}</CTableDataCell>
                                    <CTableDataCell>{sortingAlias.aliasFor}</CTableDataCell>
                                    <CTableDataCell>{sortingAlias.aliasGroup}</CTableDataCell>
                                    <CTableDataCell>
                                        <CButton color={'danger'} onClick={() => deleteAlias({ id: sortingAlias.id })}>
                                            Slett
                                        </CButton>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <CButton color={'primary'} onClick={() => handleChangeAlias(sortingAlias.id)}>
                                            Endre
                                        </CButton>
                                    </CTableDataCell>
                                </CTableRow>
                            )
                        })}
                    <CTableRow>
                        <CTableDataCell>
                            <CFormInput placeholder={'Alias'} onChange={(e) => setAlias(e.target.value)} />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormInput placeholder={'Bilnummer'} onChange={(e) => setAliasFor(e.target.value)} />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormSelect
                                placeholder={'Gruppe'}
                                options={sortingGroupOptions}
                                value={aliasGroup}
                                onChange={(e) => setAliasGroup(e.currentTarget.value)}
                            />
                        </CTableDataCell>
                        <CTableDataCell />
                        <CTableDataCell>
                            <CButton color={'success'} onClick={handleCreateAlias}>
                                Legg til
                            </CButton>
                        </CTableDataCell>
                    </CTableRow>
                </CTableBody>
            </CTable>
        </Page>
    )
}
