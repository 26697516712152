import { useEffect, useState } from 'react'
import { distributionApi } from '../store/distributionApi'
import { CAlert, CSpinner } from '@coreui/react-pro'

export default function ShipmentLabelPreview({ orderId }: { orderId: number }) {
    const { data: blob, isLoading, error } = distributionApi.useGetShipmentLabelQuery(orderId)
    useEffect(() => {
        if (blob) {
            const url = URL.createObjectURL(blob)
            setLabelUrl(url)
            return () => URL.revokeObjectURL(url)
        }
    }, [blob])
    const [labelUrl, setLabelUrl] = useState<string>('')
    if (isLoading) {
        return <CSpinner />
    }
    if (error) {
        return <CAlert color={'warning'}>{JSON.stringify(error)}</CAlert>
    }
    return <embed src={labelUrl} type="application/pdf" width="100%" height="500px" />
}
