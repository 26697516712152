import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
    CAlert,
    CButton,
    CCardBody,
    CCardLink,
    CCardSubtitle,
    CCardText,
    CCol,
    CLink,
    CRow,
    CSpinner,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableFoot,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react-pro'
import moment from 'moment'
import { useParams } from 'react-router'
import { userHasSomePermissions } from '../../auth/authUtils'
import { useAuthenticatedUser } from '../../auth/authenticationApi'
import OrderStatusBadge from '../components/OrderStatusBadge'
import PaymentDetailsCard from '../components/PaymentDetailsCard'
import { useGetOrderQuery } from '../store/orderAdminApi'
import Toolbar from '../../common/components/Toolbar'
import React from 'react'
import ShipmentLabelPreview from '../../distribution/components/ShipmentLabelPreview'
import CardHeader from '../../common/components/CardHeader'
import Card from '../../common/components/Card'
import ShipmentCard from '../../distribution/components/ShipmentCard'
import { ExportOrderToWMSButton } from '../components/ExportOrderToWMSButton'
import { WmsOrderStatusDropdown } from '../components/WmsOrderStatusDropdown'
import { AuthorizedContent } from '../../auth/AuthorizedContent'

interface OrderDetailsPageRouteParams {
    orderId: string
}

function OrderDetailsPage() {
    const { orderId } = useParams<OrderDetailsPageRouteParams>()
    const user = useAuthenticatedUser()
    const userCanViewCustomer = userHasSomePermissions(user, 'customers.read')
    const { data: order, isLoading, isError, isSuccess, error } = useGetOrderQuery(orderId)

    return (
        <div>
            {isLoading && <CSpinner></CSpinner>}
            {isError && <CAlert color={'danger'}>{error}</CAlert>}
            {isSuccess && order && (
                <>
                    <CRow className={'mt-2'}>
                        <CCol sm={7}>
                            <h1>
                                Ordre #{order.id}({order.externalId}) <OrderStatusBadge orderStatus={order.status} />
                                {order.locked && <CIcon size={'xxl'} icon={cilLockLocked} />}
                            </h1>
                        </CCol>
                        <CCol>
                            <AuthorizedContent requiredPermissions={['orders.write']}>
                                <Toolbar>
                                    <div className={'mr-1'}>
                                        <ExportOrderToWMSButton orderId={orderId} />
                                    </div>
                                    <WmsOrderStatusDropdown orderId={orderId} />
                                </Toolbar>
                            </AuthorizedContent>
                        </CCol>
                    </CRow>
                    <CRow xs={{ gutterY: 3 }}>
                        <CCol>
                            <Card>
                                <CardHeader>Kunde #{order.customer.customerId}</CardHeader>
                                <CCardBody>
                                    <CCardSubtitle className="mb-1/2 text-medium-emphasis">
                                        {order.customer.name}
                                    </CCardSubtitle>

                                    <CCardText>{order.customer.authenticated ? 'Innlogget' : 'Utlogget'}</CCardText>
                                    {userCanViewCustomer && (
                                        <CCardLink href={`/kunde/${order.customer.customerId}`}>
                                            <CButton>Vis kunde</CButton>
                                        </CCardLink>
                                    )}
                                </CCardBody>
                            </Card>
                        </CCol>
                        <CCol>
                            <Card>
                                <CardHeader>Mottaker</CardHeader>
                                <CCardBody>
                                    <CCardSubtitle className="mb-1/2 text-medium-emphasis">
                                        {order.address.firstName} {order.address.lastName}
                                    </CCardSubtitle>
                                    <CCardText component={'div'}>
                                        <address>
                                            {order.address.street} {order.address.streetNumber}
                                            <br />
                                            {order.address.zipCode} {order.address.zipName}
                                        </address>
                                        <p>
                                            Distribusjonsdato <b>{moment(order.deliveryDate).format('Do MMMM YY')}</b>
                                        </p>
                                    </CCardText>
                                    <CCardLink href="#">
                                        {!order.locked && <CButton color={'warning'}>Endre</CButton>}
                                    </CCardLink>
                                </CCardBody>
                            </Card>
                        </CCol>
                        <CCol sm={12}>
                            <Card>
                                <CardHeader>Orderlinjer</CardHeader>
                                <CCardBody>
                                    <CTable>
                                        <CTableHead>
                                            <CTableHeaderCell>Ekstern produktid</CTableHeaderCell>
                                            <CTableHeaderCell>Antall</CTableHeaderCell>
                                            <CTableHeaderCell>Vendor</CTableHeaderCell>
                                            <CTableHeaderCell>Varenavn</CTableHeaderCell>
                                            <CTableHeaderCell>Betalt</CTableHeaderCell>
                                        </CTableHead>
                                        <CTableBody>
                                            {order.orderLines.map((ol) => {
                                                return (
                                                    <CTableRow key={ol.productItemId}>
                                                        <CTableDataCell>
                                                            <CLink
                                                                href={`https://morgenlevering.no/product/${ol.externalProductId}`}
                                                                target={'_blank'}
                                                            >
                                                                {ol.externalProductId}
                                                            </CLink>
                                                        </CTableDataCell>
                                                        <CTableDataCell>{ol.quantity}</CTableDataCell>
                                                        <CTableDataCell>{ol.product?.vendor}</CTableDataCell>
                                                        <CTableDataCell>
                                                            {ol.name}{' '}
                                                            {ol.product && (
                                                                <img
                                                                    alt={ol.name}
                                                                    height={24}
                                                                    src={ol.product.imageUrl}
                                                                />
                                                            )}
                                                        </CTableDataCell>
                                                        <CTableDataCell>{ol.totalPrice.gross},-</CTableDataCell>
                                                    </CTableRow>
                                                )
                                            })}
                                        </CTableBody>
                                        <CTableFoot>
                                            <CTableRow>
                                                <CTableDataCell>Total</CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                                <CTableDataCell></CTableDataCell>
                                                <CTableDataCell>{order.calculatedPrice},-</CTableDataCell>
                                            </CTableRow>
                                        </CTableFoot>
                                    </CTable>
                                </CCardBody>
                            </Card>
                        </CCol>
                        <CCol sm={6}>
                            <PaymentDetailsCard orderId={order.id + ''} />
                        </CCol>
                        <CCol sm={6}>
                            <ShipmentCard orderId={order.id} bookingTime={order.bookingTime} />
                        </CCol>
                        <CCol sm={12}>
                            <Card>
                                <CardHeader>Etikett</CardHeader>
                                <CCardBody>
                                    <ShipmentLabelPreview orderId={order.id} />
                                </CCardBody>
                            </Card>
                        </CCol>
                    </CRow>
                </>
            )}
        </div>
    )
}

export default OrderDetailsPage
