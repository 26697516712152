import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Capacities } from '../../common/types'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { DistributionCompany } from '../types'

interface CreateDistributionCompany {
    name: string
    regionId: number
    externalId: number
    orderDeadline: string
}

interface UpdateDistributionCompanyOrderDeadline {
    id: number
    orderDeadline: string
}

interface UpdateStandardCapacity {
    companyId: number
    dayOfWeek: number
    capacity: number
}

interface ShipmentWithItems {
    shipment: Shipment
    shipmentItems: ShipmentItem[]
}

interface Shipment {
    shipmentId: number
    orderId: number
    shipmentNumber: string
    mainCarNumber: string
    routeName: string
    transportProductName: string
    externalTransportCompanyId: string
    consigneeId: string
    routeDescription: string
    routingDescription: string
    routeSeq: string
    routing: string
    routeAddress: string
    pickupZip: string
    routingDetailed: string
}

export interface ShipmentItem {
    shipmentItemId: number
    shipmentId: number
    packageLocation: string
    trackingNumber: string
    weight: string
    contents: string
    shortItemIdentifier: string
    articles: string
    jsonArticles: Article[]
}

export interface Article {
    quantity: number
    articleNumber: string
    articleName: string
}

export const distributionApi = createApi({
    reducerPath: 'distribution',
    tagTypes: ['DistributionCompany', 'DistributionCapacity'],
    baseQuery: getAuthorizedBaseQuery(),
    endpoints: (builder) => ({
        getDistributionCompanies: builder.query<DistributionCompany[], void>({
            query: () => ({
                url: '/admin/distribution/distribution-companies',
                method: 'GET',
            }),
            providesTags: ['DistributionCompany'],
        }),
        createDistributionCompany: builder.mutation<void, CreateDistributionCompany>({
            query: (data) => ({
                url: '/admin/distribution/distribution-company',
                method: 'POST',
                body: {
                    name: data.name,
                    regionId: data.regionId,
                    externalId: data.externalId,
                    orderDeadline: data.orderDeadline,
                },
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        updateDistributionCompany: builder.mutation<void, DistributionCompany>({
            query: (data) => ({
                url: `/admin/distribution/distribution-company/${data.id}`,
                method: 'PUT',
                body: {
                    name: data.name,
                    regionId: data.regionId,
                    externalId: data.externalId,
                    orderDeadline: data.orderDeadline,
                },
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        updateDistributionCompanyOrderDeadline: builder.mutation<void, UpdateDistributionCompanyOrderDeadline>({
            query: (data) => ({
                url: `/admin/distribution/distribution-company/${data.id}/order-deadline`,
                method: 'PUT',
                body: {
                    orderDeadline: data.orderDeadline,
                },
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        deleteDistributionCompany: builder.mutation<void, number>({
            query: (id) => ({
                url: `/admin/distribution/distribution-company/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['DistributionCompany'],
        }),
        getShipmentLabel: builder.query<Blob, number>({
            query: (orderId) => ({
                url: `/admin/shipment/label?orderId=${orderId}`,
                method: 'GET',
                headers: {
                    Accept: 'application/pdf',
                },
                responseHandler: (response) => response.blob(),
            }),
        }),
        getShipmentByOrderId: builder.query<ShipmentWithItems, number>({
            query: (orderId) => ({
                url: `/admin/shipment?orderId=${orderId}`,
                method: 'GET',
            }),
            transformResponse: (response: ShipmentWithItems) => {
                return {
                    ...response,
                    shipmentItems: response.shipmentItems.map((item) => ({
                        ...item,
                        jsonArticles: item.articles ? JSON.parse(item.articles) : [],
                    })),
                }
            },
        }),
        getDistributionCapacities: builder.query<Capacities[], void>({
            query: () => ({
                url: '/admin/distribution/distribution-companies/capacity/default',
                method: 'GET',
            }),
            providesTags: ['DistributionCapacity'],
        }),
        bookShipments: builder.mutation<void, number[]>({
            query: (orderIds) => ({
                url: '/admin/shipment',
                method: 'POST',
                body: { orderIds: orderIds },
            }),
        }),
        updateStandardCapacity: builder.mutation<void, UpdateStandardCapacity>({
            query: (data) => ({
                url: `/admin/distribution/distribution-company/${data.companyId}/capacity/default/${data.dayOfWeek}`,
                method: 'PUT',
                body: {
                    capacity: data.capacity,
                },
            }),
            invalidatesTags: ['DistributionCapacity'],
        }),
    }),
})

export const useDistributionCompanies = () => distributionApi.useGetDistributionCompaniesQuery().data || []
export const useDistributionCapacities = () => distributionApi.useGetDistributionCapacitiesQuery().data || []
export const {
    useCreateDistributionCompanyMutation,
    useUpdateDistributionCompanyMutation,
    useUpdateDistributionCompanyOrderDeadlineMutation,
    useDeleteDistributionCompanyMutation,
    useUpdateStandardCapacityMutation,
} = distributionApi
