import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { getAuthorizedBaseQuery } from '../../common/utils/apiUtils'
import { Order, OrderSearchResult, UpdateWmsOrderRequest, WmsOrder } from '../types'

export const orderAdminApi = createApi({
    reducerPath: 'orderAdmin',
    baseQuery: getAuthorizedBaseQuery(),
    tagTypes: ['WmsOrder'],
    endpoints: (builder) => ({
        getOrder: builder.query<Order, string>({
            query: (orderId) => ({
                url: `/admin/order/${orderId}`,
                method: 'GET',
            }),
        }),
        search: builder.query<OrderSearchResult, string>({
            query: (query) => ({
                url: `/admin/order/search?query=${query}`,
                method: 'GET',
            }),
        }),
        getOrders: builder.query<Order[], void>({
            query: () => ({
                url: `/admin/order`,
                method: 'GET',
            }),
        }),
        getWmsOrder: builder.query<WmsOrder, string>({
            query: (orderId) => ({
                url: `/admin/wms/orders/${orderId}`,
                method: 'GET',
            }),
            providesTags: (result, error, orderId) => [{ type: 'WmsOrder', id: orderId }],
        }),
        updateWmsOrder: builder.mutation<WmsOrder, { orderId: string; body: UpdateWmsOrderRequest }>({
            query: ({ orderId, body }) => ({
                url: `/admin/wms/orders/${orderId}`,
                method: 'PUT',
                body: body,
            }),
            invalidatesTags: (result, error, { orderId }) => [{ type: 'WmsOrder', id: orderId }],
        }),
        exportOrderToWms: builder.mutation<void, string>({
            query: (orderId) => ({
                url: `/admin/order/${orderId}/wms/export`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, orderId) => [{ type: 'WmsOrder', id: orderId }],
        }),
        exportUnshippedOrdersToWms: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/order/wms/export/unshipped`,
                method: 'POST',
            }),
        }),
        exportShippedOrdersToWms: builder.mutation<void, void>({
            query: () => ({
                url: `/admin/order/wms/export/shipped`,
                method: 'POST',
            }),
        }),
    }),
})

export const {
    useGetOrderQuery,
    useGetWmsOrderQuery,
    useUpdateWmsOrderMutation,
    useGetOrdersQuery,
    useSearchQuery,
    useExportOrderToWmsMutation,
    useExportUnshippedOrdersToWmsMutation,
    useExportShippedOrdersToWmsMutation,
} = orderAdminApi
