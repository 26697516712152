import { CButton } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilTransfer } from '@coreui/icons'
import React from 'react'
import { executeMutation } from '../../../common/utils/apiUtils'
import { useSyncProductsMutation } from '../../store/productApi'
import { useDispatch } from 'react-redux'

export const SynchronizeWithCraftButton = () => {
    const dispatch = useDispatch()
    const [syncProducts, { isLoading: isUpdating }] = useSyncProductsMutation()
    const handleSyncProducts = () => {
        executeMutation(
            syncProducts(),
            dispatch,
            'Produktregisteret har startet oppdatering',
            'Kunne ikke oppdatere produktregisteret',
            () => {}
        )
    }

    return (
        <CButton
            className={'mb-3 mr-1'}
            color={'primary'}
            onClick={() => {
                handleSyncProducts()
            }}
            disabled={isUpdating}
        >
            <CIcon className={'mr-1'} icon={cilTransfer} size="sm" />
            Synkroniser med Craft
        </CButton>
    )
}
