import { OrderStatus, SalesOrderStatus } from './types'
import { Colors } from '@coreui/react-pro/dist/types'

export function getColorForOrderStatus(orderStatus: OrderStatus): Colors {
    switch (orderStatus) {
        case OrderStatus.ACTIVE:
            return 'success'
        case OrderStatus.CANCELLED:
            return 'warning'
        case OrderStatus.CREDITED:
            return 'info'
        case OrderStatus.FAILED:
            return 'danger'
        default:
            return ''
    }
}

export function getLabelForWmsSalesOrderStatus(salesOrder: SalesOrderStatus | null) {
    switch (salesOrder) {
        case SalesOrderStatus.AWAIT:
            return 'Ukjent'
        case SalesOrderStatus.READY_FOR_PICKING:
            return 'Åpen'
        case SalesOrderStatus.CANCELLED:
            return 'Kansellert'
        case SalesOrderStatus.PICKED:
            return 'Plukket'
        default:
            return ''
    }
}

export function getColorForWmsSalesOrderStatus(orderStatus: SalesOrderStatus | null) {
    switch (orderStatus) {
        case SalesOrderStatus.AWAIT:
            return 'warning'
        case SalesOrderStatus.READY_FOR_PICKING:
            return 'info'
        case SalesOrderStatus.CANCELLED:
            return 'danger'
        case SalesOrderStatus.PICKED:
            return 'success'
        default:
            return ''
    }
}
