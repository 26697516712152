import { CMultiSelect } from '@coreui/react-pro'
import { Supplier2 } from '../../product/types'

interface SelectPackageLocationDropdownProps {
    suppliers?: Supplier2[]
    onSelect: (supplier: Supplier2) => void
    selected?: Supplier2
}

export const SelectSuppliersDropdown = ({ suppliers, onSelect, selected }: SelectPackageLocationDropdownProps) => {
    const sortedSuppliers = [...(suppliers || [])]
    sortedSuppliers?.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    return (
        <CMultiSelect
            multiple={false}
            placeholder={'Leverandør...'}
            optionsStyle={'text'}
            onChange={(e) => {
                const supplier = sortedSuppliers.find((supplier) => supplier.id === e[0].value)
                if (supplier) {
                    onSelect(supplier)
                } else {
                    alert('Kunne ikke finne leverandør')
                }
            }}
            options={sortedSuppliers.map((supplier) => {
                return { value: supplier.id, text: supplier.name }
            })}
        />
    )
}
