import CIcon from '@coreui/icons-react'
import { cilVerticalAlignTop } from '@coreui/icons'
import { CButton } from '@coreui/react-pro'
import React, { FC } from 'react'
import { useExportOrderToWmsMutation } from '../store/orderAdminApi'
import { executeMutation } from '../../common/utils/apiUtils'
import { useDispatch } from 'react-redux'

interface ExportOrderToWMSButtonProps {
    orderId: string
}

export const ExportOrderToWMSButton: FC<ExportOrderToWMSButtonProps> = ({ orderId }) => {
    const [exportOrder, { isLoading: isExporting }] = useExportOrderToWmsMutation()
    const dispatch = useDispatch()

    const handleExport = () => {
        executeMutation(exportOrder(orderId), dispatch, 'Ordre eksportert', 'Kunne ikke eksportere ordre', () => {})
    }

    return (
        <CButton variant={'outline'} disabled={isExporting} onClick={handleExport}>
            <CIcon className={'mr-1'} icon={cilVerticalAlignTop} size="sm" />
            {isExporting ? 'Eksporterer...' : 'Eksporter til WMS'}
        </CButton>
    )
}
