import { RegisteredProduct } from '../types'
import Card from '../../common/components/Card'
import PublishToRegionSelect from './PublishToRegionSelect'
import { CCardBody } from '@coreui/react-pro'
import CardHeader from '../../common/components/CardHeader'
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilGlobeAlt } from '@coreui/icons'
import ProductVisibilityCheckbox from './ProductVisibilityCheckbox'
import PickInBoxCheckbox from './PickInBoxCheckbox'

interface PublishProductCardProps {
    product: RegisteredProduct
}
export default function PublishProductCard({ product }: PublishProductCardProps) {
    return (
        <Card>
            <CardHeader>
                <CIcon icon={cilGlobeAlt} size={'xl'} className={'mr-1'} />
                Regioner
            </CardHeader>
            <CCardBody>
                <p style={{ color: 'var(--cui-gray)' }}>
                    Velg hvilke regioner produktet skal være tilgjengelig for salg i
                </p>
                <PublishToRegionSelect productId={product.id} activeRegionIds={product.activeInRegions} />
                <hr />
                <PickInBoxCheckbox productId={product.id} packInBox={product.packInBox} />
                <ProductVisibilityCheckbox productId={product.id} visible={product.visible} />
            </CCardBody>
        </Card>
    )
}
