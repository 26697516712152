import { CFormSwitch } from '@coreui/react-pro'
import { ChangeEvent, useState } from 'react'
import { useSetPackInBoxMutation } from '../store/productApi'

export default function PickInBoxCheckbox({ productId, packInBox }: { productId: string; packInBox: boolean }) {
    const [updateVisibility] = useSetPackInBoxMutation()
    const [boxPick, setBoxPick] = useState(packInBox)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBoxPick(e.target.checked)
        updateVisibility({ productId, packInBox: e.target.checked })
    }
    return (
        <CFormSwitch
            style={
                {
                    '--cui-form-check-input-checked-bg-color': 'var(--cui-danger)',
                    '--cui-form-check-input-checked-border-color': 'var(--cui-danger)',
                } as React.CSSProperties
            }
            label={'Ordre med dette produktet plukkes i eske'}
            className={'mb-3'}
            size={'xl'}
            onChange={handleChange}
            checked={boxPick}
        />
    )
}
