import { CButton } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilCloudDownload } from '@coreui/icons'
import React from 'react'
import { RegisteredProduct } from '../../types'
import JsBarcode from 'jsbarcode'
import { jsPDF } from 'jspdf'

interface CreateWarehousePDFButtonProps {
    products: RegisteredProduct[]
}
export const CreateWarehousePDFButton = ({ products }: CreateWarehousePDFButtonProps) => {
    const selectedRegions = () => {
        const searchParams = new URLSearchParams(window.location.search)
        return searchParams.get('region')?.split('-')
    }

    const generateBarcode = (sku: string): string => {
        const canvas = document.createElement('canvas')
        JsBarcode(canvas, sku, {
            format: 'CODE128',
            width: 1,
            height: 20,
        })
        return canvas.toDataURL('image/png')
    }

    const generatePDF = () => {
        const doc = new jsPDF()
        let yOffset = 20 // Vertical position in PDF
        let xOffset = 10 // Horizontal position for the first column

        const validProducts = products
            .filter((product) => product.sku && product.sku.trim() !== '')
            .filter((product) =>
                product.activeInRegions.some((region) => selectedRegions()?.includes(region.toString()))
            )
            .filter((product) => product.supplierId)
            .filter((product) => product.status === 'ACTIVE')

        const groupedProducts = validProducts.reduce((acc, product) => {
            if (!acc[product.supplierId]) {
                acc[product.supplierId] = {
                    supplierName: product.supplierName,
                    products: [],
                }
            }
            acc[product.supplierId].products.push(product)
            return acc
        }, {} as Record<string, { supplierName: string; products: RegisteredProduct[] }>)

        const sortedSuppliers = Object.entries(groupedProducts)
            .map(([supplierId, group]) => ({
                supplierId,
                supplierName: group.supplierName,
                products: group.products,
            }))
            .filter((group) => group.products.length > 0)
            .sort((a, b) => (a.supplierName || '').localeCompare(b.supplierName))

        sortedSuppliers.forEach((supplier, supplierIndex) => {
            if (supplierIndex > 0) {
                doc.addPage()
                yOffset = 20
            }

            doc.setFontSize(18)
            doc.text(`${supplier.supplierName}`, 10, yOffset)
            yOffset += 10

            supplier.products.forEach((product) => {
                // Add product title
                doc.setFontSize(12)
                doc.text(`${product.title}`, xOffset, yOffset + 5)

                // Generate and add barcode
                try {
                    const barcodeImage = generateBarcode(product.sku)
                    doc.addImage(barcodeImage, 'PNG', xOffset, yOffset + 10, 80, 30)
                } catch (e) {
                    console.error('Failed to generate barcode', e)
                }

                // Switch to the second column or start a new row
                if (xOffset === 10) {
                    xOffset = 110 // Move to the second column
                } else {
                    xOffset = 10 // Reset to the first column
                    yOffset += 50 // Move to the next row
                }

                // Handle page breaks
                if (yOffset > 270) {
                    doc.addPage()
                    yOffset = 20
                    xOffset = 10
                }
            })

            // Reset to the first column at the end of each supplier
            xOffset = 10
        })

        doc.save('products_by_supplier.pdf')
    }

    const handleCreateWarehousePDF = () => {
        generatePDF()
    }

    return (
        <CButton color={'secondary'} className={'mb-3'} onClick={handleCreateWarehousePDF}>
            <CIcon className={'mr-1'} icon={cilCloudDownload} size="sm" />
            Opprett PDF til lager
        </CButton>
    )
}
