import { CButton } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilCloudDownload } from '@coreui/icons'
import React from 'react'
import authenticatedClient from '../../../services/axios'
import { apiUrl } from '../../../config'

export const DownloadProductRegistryAsCsvButton = () => {
    const downloadFile = () =>
        authenticatedClient
            .get(`${apiUrl}/admin/product-registry`, {
                headers: {
                    Accept: 'text/csv',
                    'Content-Type': 'text/csv',
                },
            })
            .then(({ data }) => {
                const blob = new Blob([data])
                const url = window.URL.createObjectURL(blob)
                const anchor = document.createElement('a')
                anchor.setAttribute('href', url)
                anchor.setAttribute('download', `produkt-register.csv`)
                anchor.click()
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => console.error(error.response.data))

    return (
        <CButton color={'secondary'} className={'mb-3 mr-1'} onClick={downloadFile}>
            <CIcon className={'mr-1'} icon={cilCloudDownload} size="sm" />
            Last ned som CSV
        </CButton>
    )
}
